import { makeStyles } from '@material-ui/core';
import DialogPrint from 'components/dialog/dialog-print/DialogPrint';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useEffect } from 'react';
import { useSelector } from 'store/redux/selector';

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 120px 120px',
    marginBottom: 5,
    paddingBottom: 3,
    borderBottom: '1px solid #ccc',
  },
  headerInfo: {
    marginTop: 10,
  },
});

interface TopProductsReportDataProps {
  data: any;
  onExited(): void;
}

const TopProductsReportData: React.FC<TopProductsReportDataProps> = ({ data, onExited }) => {
  const classes = useStyles();
  const user = useSelector(state => state.user);

  useEffect(() => {
    // setTimeout(() => window.print(), 500);
  }, []);

  return (
    <DialogPrint onExited={onExited} title="Relatório">
      <div className="header">
        <div>
          <h6>Total por produtos</h6>
        </div>
        <div>
          <p>{user?.name}</p>
          <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
        </div>
      </div>
      <div className={`${classes.row} table-header`}>
        <p>Descrição</p>
        <p style={{ textAlign: 'center' }}>Qtd</p>
        <p>Total</p>
      </div>
      {data.map(item => (
        <div key={item.name} className={classes.row}>
          <p>{item.name}</p>
          <p style={{ textAlign: 'center' }}>{item.amount}</p>
          <p>{item.formattedFinalPrice}</p>
        </div>
      ))}
    </DialogPrint>
  );
};

export default TopProductsReportData;
