import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DialogPrint from 'components/dialog/dialog-print/DialogPrint';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { moneyFormat } from 'helpers/NumberFormat';

const useStyles = makeStyles({
  container: {
    flex: 1,
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 150px 150px',
    marginBottom: 5,
    paddingBottom: 3,
    borderBottom: '1px solid #ccc',
  },
  headerInfo: {
    marginTop: 10,
  },
  total: {
    display: 'grid',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    marginTop: 20,
    gridTemplateColumns: '115px 100px',
    border: '1px solid #ccc',
    padding: 15,
    '& > .label': {
      //
    },
    '& > .value': {
      fontSize: 20,
      fontWeight: 500,
      display: 'inline-flex',
      justifyContent: 'flex-end',
    },
  },
});

ReportTopCustomersData.propTypes = {
  reportData: PropTypes.array.isRequired,
  onExited: PropTypes.func.isRequired,
};

export default function ReportTopCustomersData({ reportData, onExited }) {
  const classes = useStyles();
  const user = useSelector(state => state.user);

  const formattedTotal = useMemo(() => {
    const total = reportData.reduce((sum, item) => (sum = sum + item.total), 0);
    return moneyFormat(total);
  }, [reportData]);

  const quantity = useMemo(() => {
    return reportData.reduce((sum, item) => (sum = sum + item.quantity), 0);
  }, [reportData]);

  return (
    <DialogPrint title="Relatório" onExited={onExited}>
      <div className="header">
        <h6>Total de pedidos por clientes</h6>
        <div>
          <p>{user?.name}</p>
          <p>{format(new Date(), 'PPpp', { locale: ptBR })}</p>
        </div>
      </div>
      <div className={`${classes.row} table-header`}>
        <p>Cliente</p>
        <p className={classes.head}>Total</p>
        <p className={classes.head}>Quantidade</p>
      </div>

      {reportData.map(item => (
        <div key={item.id} className={classes.row}>
          <p>{item.name}</p>
          <p>{item.formattedTotal}</p>
          <p>{item.quantity}</p>
        </div>
      ))}

      <div className={classes.total}>
        <p className="label">TOTAL</p>
        <p className="value">{formattedTotal}</p>
        <p className="label">QUANTIDADE</p>
        <p className="value">{quantity}</p>
      </div>
    </DialogPrint>
  );
}
