import { makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useFetchReceipts } from './hooks/useFetchReceipts';
import { ReceiptsProvider } from './hooks/useReceipts';
import { Receipt, ReceiptHistory, ReceiptOnList } from './types/receipt';
import Appbar from 'components/appbar/Appbar';
import PaginationProvider from 'hooks/pagination';
import ApiPagination from 'components/_pagination/ApiPagination';
import TableLoading from 'components/loading/TableLoading';
import ModuleLoading from 'components/loading/ModuleLoading';
import NoData from 'components/nodata/NoData';
import ReceiptListTable from './list/table/ReceiptListTable';
import ReceiptListModule from './list/module/ReceiptListModule';
import useTableOrder from 'hooks/tableOrder';
import ReceiptsFilterBox from './ReceiptsFilterBox';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';
import { useReceiptSocket } from 'hooks/useReceiptSocket';
import { useIntegratorConnectionStatus } from './hooks/useIntegratorConnectionStatus';
import ReceiptsAppbarButtons from './ReceiptsAppbarButtons';
import ReceiptsMobileFilter from './ReceiptsMobileFilter';
import { useReceiptSocketEvents } from './hooks/useReceiptsSocketEvents';
import { useErrorHandler } from 'providers/error-handler/error-handler';
import { useApp } from 'App';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import ReceiptsPageHeaderButtons from './ReceiptsPageHeaderButtons';
import ReceiptHistoriesDialog from './histories/ReceiptHistoriesDialog';
import ReceiptTotals from './ReceiptTotals';
import DownloadReceiptsModal from './DownloadReceiptsModal';
import ReceiptsIntegratorStatusModal from './ReceiptsIntegratorStatusModal';
import CancelReceiptModal from './cancelation/CancelReceiptModal';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

export type ReceiptsQueryParams = {
  page: number;
  rows: number;
  initial_date: Date;
  final_date: Date;
  status: 'authorized' | 'cancelled' | 'created' | 'all';
};

let timer: NodeJS.Timeout;

const Receipts: React.FC = () => {
  const classes = styles();
  const { receipts, fetch, total, loading, setQueryParams, queryParams, setReceipts, totals } = useFetchReceipts();
  const [selectedReceipt, setSelectedReceipt] = useState<Receipt | null>(null);
  const [filtered, setFiltered] = useState<ReceiptOnList[]>([]);
  const [orderedIndex, sort] = useTableOrder();
  const [term, setTerm] = useState('');
  const [saving, setSaving] = useState(false);
  const [socket] = useReceiptSocket();
  const { isIntegratorConnected } = useIntegratorConnectionStatus(socket);
  const [isMobileFilterOpened, setIsMobileFilterOpened] = useState(false);
  const { showErrorDialog } = useErrorHandler();
  const app = useApp();
  const [displayMode, setDisplayMode] = useState<'list' | 'module'>(app.isMobile ? 'module' : 'list');
  const [selectedReceiptHistories, setSelectedReceiptHistories] = useState<ReceiptHistory[]>([]);
  const [downloadReceiptsModal, setDownloadReceiptsModal] = useState(false);
  const [integratorStatusModal, setIntegratorStatusModal] = useState(false);
  const [cancelReceiptModal, setCancelReceiptModal] = useState(false);

  useReceiptSocketEvents(setReceipts);

  useEffect(() => {
    setFiltered(receipts);
  }, [receipts]);

  useEffect(() => {
    fetch('');
  }, [fetch]);

  useEffect(() => {
    if (app.windowWidth >= 930) {
      setDisplayMode('list');
      return;
    }

    setDisplayMode('module');
  }, [app.isMobile, app.windowWidth]);

  function handleParamsChange(index: keyof ReceiptsQueryParams, value: string | number) {
    setQueryParams(state => ({
      ...state,
      [index]: value,
    }));
  }

  function handleSearch(value: string) {
    setTerm(value);

    clearTimeout(timer);

    timer = setTimeout(() => fetch(value), 500);
  }

  function handleSort(index: string) {
    const p = sort(index, filtered);
    setFiltered(p);
  }

  function handlePrint() {
    setSaving(true);

    api
      .post(`/receipts/${selectedReceipt?.id}/print`)
      .catch(err => {
        showErrorDialog({
          message: 'Não foi possível imprimir o cupom',
          error: err,
        });
      })
      .finally(() => setSaving(false));
  }

  const handleCancel = useCallback(
    async (justification: string) => {
      await api.post(`/receipts/${selectedReceipt?.id}/cancel`, { justification });
    },
    [selectedReceipt]
  );

  const handleAuthorize = useCallback(() => {
    setSaving(true);

    api
      .post(`/receipts/${selectedReceipt?.id}/authorize`)
      .catch(err => {
        showErrorDialog({
          message: 'Não foi possível autorizar o cupom',
          error: err,
        });
      })
      .finally(() => setSaving(false));
  }, [selectedReceipt, showErrorDialog]);

  const handleGetReceiptHistories = useCallback(() => {
    setSaving(true);

    api
      .get(`/receipts/${selectedReceipt?.id}/histories`)
      .then(response => setSelectedReceiptHistories(response.data))
      .catch(err => {
        showErrorDialog({
          message: 'Não foi possível recuperar o histório do cupom',
          error: err,
        });
      })
      .finally(() => setSaving(false));
  }, [selectedReceipt, showErrorDialog]);

  function handleDownload() {
    window.open(selectedReceipt?.authorized_download_url);
  }

  function handleView() {
    window.open(selectedReceipt?.authorized_document_url);
  }

  return (
    <ReceiptsProvider
      value={{
        selectedReceipt,
        setSelectedReceipt,
        handleView,
        handleDownload,
        handlePrint,
        handleCancel,
        isIntegratorConnected,
        handleAuthorize,
        handleGetReceiptHistories,
        setCancelReceiptModal,
      }}
    >
      {saving && <Loading />}

      {isMobileFilterOpened && (
        <ReceiptsMobileFilter
          queryParams={queryParams}
          handleParamsChange={handleParamsChange}
          onExited={() => setIsMobileFilterOpened(false)}
        />
      )}

      {downloadReceiptsModal && <DownloadReceiptsModal onExited={() => setDownloadReceiptsModal(false)} />}

      {integratorStatusModal && <ReceiptsIntegratorStatusModal onExited={() => setIntegratorStatusModal(false)} />}

      {selectedReceiptHistories.length > 0 && (
        <ReceiptHistoriesDialog histories={selectedReceiptHistories} onExited={() => setSelectedReceiptHistories([])} />
      )}

      {cancelReceiptModal && <CancelReceiptModal onExited={() => setCancelReceiptModal(false)} />}

      <div className={classes.container}>
        <Appbar
          title="Cupons fiscais"
          ActionComponents={
            <ReceiptsAppbarButtons
              openDownloadReceiptModal={() => setDownloadReceiptsModal(true)}
              openDialog={() => setIsMobileFilterOpened(true)}
              openIntegratorStatusModal={() => setIntegratorStatusModal(true)}
            />
          }
        />

        <PageHeaderActions
          title="Gerenciador de cupons fiscais"
          description="Autorização e cancelamento dos cupons fiscais"
          ActionComponent={
            <ReceiptsPageHeaderButtons openDownloadReceiptModal={() => setDownloadReceiptsModal(true)} />
          }
        />

        <ReceiptsFilterBox
          displayMode={displayMode}
          setDisplayMode={setDisplayMode}
          params={queryParams}
          handleParamsChange={handleParamsChange}
          handleSearch={handleSearch}
          term={term}
        />

        <ReceiptTotals totals={totals} />

        <PaginationProvider>
          {loading ? (
            displayMode === 'list' ? (
              <TableLoading />
            ) : (
              <ModuleLoading />
            )
          ) : filtered.length === 0 ? (
            <NoData message="Nenhum cupom para mostrar" />
          ) : (
            <div className={classes.container}>
              {displayMode === 'list' ? (
                <ReceiptListTable receipts={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                displayMode === 'module' && <ReceiptListModule receipts={filtered} />
              )}
            </div>
          )}

          <ApiPagination
            onChangePage={value => handleParamsChange('page', value)}
            onChangeRowsPerPage={value => handleParamsChange('rows', value)}
            count={total}
          />
        </PaginationProvider>
      </div>
    </ReceiptsProvider>
  );
};

export default Receipts;
