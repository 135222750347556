import React from 'react';
import { Tooltip, IconButton, makeStyles } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Search } from '@material-ui/icons';
import { useOrder } from 'pages/board-management/registration/registration/hooks/useOrder';
import { useSelector } from 'store/redux/selector';

const styles = makeStyles(theme => ({
  badge: {
    position: 'fixed',
    top: 10,
    color: 'inherit',
    right: 30,
    fontSize: 14,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      top: 5,
      right: 22,
    },
  },
  button: {
    position: 'relative',
  },
}));

interface OrderActionProps {
  setDialogResumeOrder(): void;
}

const OrderAction: React.FC<OrderActionProps> = ({ setDialogResumeOrder }) => {
  const { setShowSearchDialog } = useOrder();
  const order = useSelector(state => state.order);
  const classes = styles();

  return (
    <>
      <Tooltip title="Pesquisar produtos">
        <IconButton color="inherit" onClick={() => setShowSearchDialog(true)}>
          <Search />
        </IconButton>
      </Tooltip>
      <Tooltip title="resumo do pedido">
        <IconButton color="inherit" onClick={setDialogResumeOrder} className={classes.button}>
          <span className={classes.badge}>{order.products.length}</span>
          <AssignmentIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default OrderAction;
