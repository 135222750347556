import React from 'react';
import { Menu, MenuItem } from '@material-ui/core';
import { useReceipts } from '../hooks/useReceipts';
import { useMessaging } from 'hooks/messaging';

type ReceiptListMenuProps = {
  anchorEl: HTMLButtonElement | null;
  setAnchorEl(anchorEl: null | HTMLButtonElement): void;
};

type Actions = 'authorize' | 'cancel' | 'print';

const ReceiptListMenu: React.FC<ReceiptListMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const {
    selectedReceipt,
    handlePrint,
    setCancelReceiptModal,
    isIntegratorConnected,
    handleAuthorize,
    handleGetReceiptHistories,
  } = useReceipts();
  const { handleOpen } = useMessaging();

  function handleCancelClick() {
    setCancelReceiptModal(true);
    setAnchorEl(null);
  }

  function handlePrintClick() {
    handlePrint();
    setAnchorEl(null);
  }

  function handleAuthorizeClick() {
    handleAuthorize();
    setAnchorEl(null);
  }

  function handleGetReceiptHistoriesClick() {
    handleGetReceiptHistories();
    setAnchorEl(null);
  }

  function handleIDCopy() {
    navigator.clipboard.writeText(selectedReceipt?.id as string).then(() => {
      handleOpen('Copiado');
    });

    setAnchorEl(null);
  }

  function canBeAuthorized(): boolean {
    if (!selectedReceipt) {
      return false;
    }

    const options = {
      created: true,
      authorized: false,
      authorization_failed: true,
      cancellation_failed: false,
    };

    return options[selectedReceipt.current_status];
  }

  function canBeCancelled(): boolean {
    if (!selectedReceipt) {
      return false;
    }

    const options = {
      created: true,
      authorized: true,
      cancelled: false,
      authorization_failed: true,
      cancellation_failed: true,
    };

    return options[selectedReceipt.current_status];
  }

  function canBePrinted(): boolean {
    if (!selectedReceipt) {
      return false;
    }

    const options = {
      cancellation_failed: true,
      authorized: true,
      created: false,
      cancelled: false,
      authorization_failed: false,
    };

    return options[selectedReceipt.current_status];
  }

  function isMenuOptionEnabled(action: Actions): boolean {
    const mapping = {
      nfce: {
        authorize: canBeAuthorized(),
        cancel: canBeCancelled(),
        print: canBePrinted() && isIntegratorConnected,
      },
      sat: {
        authorize: canBeAuthorized() && isIntegratorConnected,
        cancel: canBeCancelled() && isIntegratorConnected,
        print: canBePrinted() && isIntegratorConnected,
      },
    };

    if (!selectedReceipt?.fiscal_serial) {
      return false;
    }

    return mapping[selectedReceipt.fiscal_serial.type][action];
  }

  return (
    <>
      {selectedReceipt && (
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
          <MenuItem disabled={!isMenuOptionEnabled('authorize')} onClick={handleAuthorizeClick}>
            autorizar
          </MenuItem>

          <MenuItem disabled={!isMenuOptionEnabled('cancel')} onClick={handleCancelClick}>
            cancelar
          </MenuItem>

          <MenuItem disabled={!isMenuOptionEnabled('print')} onClick={handlePrintClick}>
            imprimir
          </MenuItem>

          <MenuItem onClick={handleGetReceiptHistoriesClick}>histórico</MenuItem>
          <MenuItem onClick={handleIDCopy}>copiar ID</MenuItem>
        </Menu>
      )}
    </>
  );
};

export default ReceiptListMenu;
