import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { Receipt } from '../types/receipt';

interface ReceiptContextValue {
  selectedReceipt: Receipt | null;
  setSelectedReceipt: Dispatch<SetStateAction<Receipt | null>>;
  handlePrint(): void;
  handleDownload(): void;
  handleView(): void;
  handleCancel(justification: string): Promise<void>;
  isIntegratorConnected: boolean;
  handleAuthorize(): void;
  handleGetReceiptHistories(): void;
  setCancelReceiptModal: Dispatch<SetStateAction<boolean>>;
}

const ReceiptsContext = createContext<ReceiptContextValue>({} as ReceiptContextValue);
export const ReceiptsProvider = ReceiptsContext.Provider;
export const ReceiptsConsumer = ReceiptsContext.Consumer;

export function useReceipts(): ReceiptContextValue {
  return useContext(ReceiptsContext);
}
