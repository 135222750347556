import React, { useState, useRef, useEffect } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import CustomAppbar from 'components/appbar/Appbar';
import PageHeader from 'components/page-header/PageHeader';
import KeyboardDatePicker from 'components/pickers/KeyboardDatePicker';
import TopProductsReportData from './TopProductsReportData';
import { api } from 'services/api';
import Loading from 'components/loading/Loading';

import { endOfDay, startOfDay, subDays } from 'date-fns';

import { useTopProductsReportValidation } from './validation/topProductsReportValidation';
import { moneyFormat } from 'helpers/NumberFormat';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  dateFilter: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 0',
    maxWidth: 400,
  },
  actions: {
    marginTop: 30,
  },
});

const TopProducts: React.FC = () => {
  const classes = styles();
  const [initialDate, setInitialDate] = useState<Date | null>(subDays(new Date(), 90));
  const [finalDate, setFinalDate] = useState<Date | null>(new Date());
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [validation, setValidation, validate] = useTopProductsReportValidation();

  useEffect(() => {
    const [key] = Object.keys(validation) as [keyof typeof inputs];

    if (!key) return;

    inputs[key].current?.focus();
  }, [validation]); // eslint-disable-line

  const inputs = {
    initialDate: useRef<HTMLInputElement>(null),
    finalDate: useRef<HTMLInputElement>(null),
  };

  function handleValidation() {
    setValidation({});

    validate({ initialDate, finalDate })
      .then(handleSubmit)
      .catch(err => console.error(err));
  }

  function handleSubmit() {
    setLoading(true);

    const _initialDate = !initialDate ? null : startOfDay(initialDate);
    const _finalDate = !finalDate ? null : endOfDay(finalDate);

    api
      .get('/reports/top-products', { params: { initialDate: _initialDate, finalDate: _finalDate } })
      .then(response => {
        setData(
          response.data.map(item => ({
            ...item,
            formattedFinalPrice: moneyFormat(item.final_price),
          }))
        );
      })
      .catch(err => console.error(err))
      .finally(() => setLoading(false));
  }

  return (
    <div className={classes.container}>
      {loading && <Loading />}
      {data && <TopProductsReportData data={data} onExited={() => setData(null)} />}
      <CustomAppbar title="Relatórios" />
      <PageHeader title="Total por produtos" description="Relação da quatidade e total de produtos vendidos" />
      <div className={classes.dateFilter}>
        <KeyboardDatePicker
          inputRef={inputs.initialDate}
          error={!!validation.initialDate}
          helperText={validation.initialDate}
          autoOk
          label="Data inicial"
          fullWidth
          value={initialDate}
          onChange={date => setInitialDate(date)}
          margin="normal"
          autoFocus
        />

        <KeyboardDatePicker
          inputRef={inputs.finalDate}
          error={!!validation.finalDate}
          helperText={validation.finalDate}
          autoOk
          label="Data final"
          fullWidth
          value={finalDate}
          onChange={date => setFinalDate(date)}
          margin="normal"
        />

        <div className={classes.actions}>
          <Button variant="contained" color="primary" onClick={handleValidation}>
            Gerar relatório
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TopProducts;
