import { FC, Dispatch, SetStateAction } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useOrder } from '../hooks/useOrder';
import { OrderProduct } from 'types/order';

const useStyles = makeStyles(theme => ({
  span: {
    marginRight: 10,
  },
  categoryName: {
    display: 'block',
    minWidth: 150,
    fontWeight: 300,
  },
  doneIcon: {
    marginLeft: 10,
  },
  actions: {
    display: 'flex',
    '& p': {
      marginRight: 7,
      cursor: 'pointer',
      fontSize: 16,
    },
  },
  productdata: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  deleteLink: {
    color: theme.palette.error.main,
  },
}));

interface ProductStatusProps {
  product: OrderProduct;
  setSelectedProduct: Dispatch<SetStateAction<OrderProduct | null>>;
}

const ProductStatus: FC<ProductStatusProps> = ({ product, setSelectedProduct }) => {
  const classes = useStyles();
  const { handleRemoveProduct } = useOrder();

  function handleUpdateClick() {
    setSelectedProduct(product);
  }

  function handleRemoveClick() {
    handleRemoveProduct(product.uid);
  }

  return (
    <>
      <div className={classes.productdata}>
        <Typography key={product.id} className={classes.categoryName}>
          <span className={classes.span}>{product.amount}x</span>
          <span className={classes.span}>{product.name}</span>
        </Typography>

        <Typography color="textSecondary" className={classes.span}>
          {product.formattedFinalPrice}
        </Typography>
      </div>

      <div className={classes.actions}>
        <Typography onClick={handleUpdateClick}>editar</Typography>

        <Typography className={classes.deleteLink} onClick={handleRemoveClick}>
          excluir
        </Typography>
      </div>
    </>
  );
};

export default ProductStatus;
